<template>
  <div class="container">
    <div class="form-group row mb-2">
      <div class="add-role">
        <ButtonMedium
          v-can="'teaching.add_roles'"
          v-if="allows_crud"
          :tooltip_text="`Crear Rol`"
          :text_button="`Crear`"
          :click_button="() => $bvModal.show(`new-role-modal`)"
          :icon="'plus'"
          :variant="'primary'"
        >
        </ButtonMedium>
      </div>
    </div>
    <b-table
      :key="key_group_table"
      class="mt-3"
      tdClass="td-roles"
      :items="role_names"
      :fields="role_fields"
      :sort-by.sync="sortBy"
      responsive
      hover
    >
      <template v-slot:cell(position_actions)="row">
        <b-modal
          :id="`edit-role-modal-${row.item.id}`"
          title="Modificar Nombre del Rol"
          size="md"
          hide-footer
        >
          <RoleForm
            :Role="row.item"
            :Groups="groups"
            @updated="slotUpdatedRole"
            :show_title="false"
          ></RoleForm>
        </b-modal>
        <ButtonSmall
          tabindex="-1"
          v-if="allows_crud"
          v-can="'teaching.change_roles'"
          :click_button="() => $bvModal.show(`edit-role-modal-${row.item.id}`)"
          :tooltip_text="`Modificar Nombre del Rol`"
          :icon="'square'"
        ></ButtonSmall>
        <ButtonSmall
          tabindex="-1"
          v-if="allows_crud"
          v-can="'teaching.delete_roles'"
          :click_button="() => askForDeleteRole(row.item.id)"
          :tooltip_text="`Eliminar Rol`"
          :icon="'trash'"
        ></ButtonSmall>
      </template>
    </b-table>
    <div>
      <b-modal
        id="new-role-modal"
        title="Crear Nuevo Nombre del Rol"
        size="md"
        hide-footer
      >
        <RoleForm
          @created="slotCreatedRole"
          :Groups="groups"
          :show_title="false"
        ></RoleForm>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { toast } from "@/utils/utils";

export default {
  name: "GroupNameAssignment",
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RoleForm: () => import("./RoleForm"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  data() {
    return {
      role_alias: "",
      selected_role: null,
      role_names: [],
      key_group_table: 0,
      groups: [],
      sortBy: "position",
      role_fields: [
        { key: "position_name", label: "Rol", tdClass: "text-left" },
        {
          key: "position",
          label: "Grupo",
          sortable: true,
          tdClass: "text-left",
          formatter: (value) => {
            return this.getGroupName(value);
          },
        },
        { key: "position_actions", label: "" },
      ],
    };
  },
  methods: {
    fetchRoleNames() {
      this.$restful.Get(`/teaching/roles/`).then((response) => {
        this.role_names = response;
      });
    },
    slotCreatedRole(response) {
      this.role_names.push(response);
      this.$bvModal.hide("new-role-modal");
      this.key_group_table++;
      this.$emit("created", response);
    },
    slotUpdatedRole(role) {
      const index = this.role_names.findIndex((x) => x.id == role.id);
      if (index != -1) {
        this.role_names[index] = role;
        this.key_group_table++;
      }
      this.$bvModal.hide(`edit-role-modal-${role.id}`);
      this.$emit("updated", role);
    },
    getGroupName(group_id) {
      const group = this.groups.find((x) => x.id == group_id);
      return group ? group.name : "";
    },
    askForDeleteRole(role_id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Rol?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful.Delete(`/teaching/roles/${role_id}/`).then(() => {
            const index = this.role_names.findIndex((x) => x.id == role_id);
            if (index != -1) {
              this.role_names.splice(index, 1);
              this.$emit("delete", role_id);
              this.key_group_table++;
            }
            toast("Rol eliminado.");
          });
        }
      });
    },
    fetchRolePositions() {
      this.$restful.Get(`/authentication/group/`).then((response) => {
        this.groups = response
          .filter((x) => ![2, 5].includes(x.id))
          .sort((a, b) => a.id - b.id);
      });
    },
  },
  created() {
    this.fetchRolePositions();
    this.fetchRoleNames();
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  border-radius: 10px;
  background-color: rgb(240, 240, 240);
  box-shadow: 1.5px 2px 3px -2px rgb(119, 119, 119);
  padding: 2rem;
}
.designation-card {
  display: flex;
}
.assignment-btn {
  margin-top: 2%;
}
.alias-section,
.group-section {
  width: 50%;
}
.group-section {
  margin-left: 1%;
}
.td-roles {
  max-width: 33% !important;
}
</style>